// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import mastodonIconSvg from '../assets/Mastodon_Logotype_(Simple).svg'
export default createVuetify(
    {
        icons: {
            iconfont: 'mdi',
            values: {
                mastodon: mastodonIconSvg
            },
        }
    }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
