<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
        <v-img
            alt="Modicum Logo"
            className="shrink mr-2"
            contain
            :src="require('./assets/ONE-dino-logo.png')"
            height="64" width="61"
            transition="scale-transition"
        />
        <v-toolbar-title>Modicum One</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container>

        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>In search of a little bit of something good</v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item-group>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title ><img width="16" :src="require('./assets/Mastodon_Logotype_(Simple).svg')"/> <a rel="me" href="https://mastodon.sdf.org/@dep">Mastodon</a></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title><h3>Snake</h3></v-card-title>
              <v-card-subtitle><a href="/snake/">demo</a> <a href="https://github.com/modicum-one/snake">source code</a>
              </v-card-subtitle>
              <v-img
                  :src="require('./assets/screencap-snake.png')"
                  contain
                  max-height="256"/>
              <v-card-text><p>Simple JS snake game in vue</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title><h3>Bogglish</h3></v-card-title>
              <v-card-subtitle><a href="/bogglish/">demo</a> <a href="https://github.com/modicum-one/bogglish">source
                code</a></v-card-subtitle>
              <v-img
                  :src="require('./assets/screencap-bogglish.png')"
                  contain
                  max-height="256"/>
              <v-card-text><p>Simple JS boggle game in vue. (good for playing over video chat)</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title><h3>Melaneo</h3></v-card-title>
              <v-card-subtitle><a href="/melaneo-html-dist/Meloneo.html">demo</a></v-card-subtitle>
              <v-img
                  :src="require('./assets/screencap-melaneo.png')"
                  contain
                  max-height="256"/>
              <v-card-text><p>2d platform game from the mind of a 7 year old. Written in <a
                  href="https://godotengine.org">Godot</a> game engine</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title><h3>Fretboard</h3></v-card-title>
              <v-card-subtitle><a href="/fretboard">demo</a></v-card-subtitle>
              <v-img
                  :src="require('./assets/screencap-fretboard.png')"
                  contain
                  max-height="256"/>
              <v-card-text><p>Fretboard Visualizer</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {},

  data: () => ({
    //
  }),
};
</script>
